import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import App from "./App";
import FontStyles from "./fontStyles";

import { store } from "./store";
import { BrowserRouter } from "react-router-dom";
import PaymentButtonWidget from "./pages/payment-btn-widget";

let initConfig = null;

export default {
    config: (config) => {
        if (!config.order?.merchant_id) {
            config.order.merchant_id = 3;
        }
        initConfig = config;
    },
    widgets: {
        init: () => {
            return {
                render: () => {
                    ReactDOM.render(
                        <BrowserRouter>
                            <Provider store={store}>
                                <FontStyles />
                                <App
                                    appID={initConfig.appID}
                                    order={initConfig.order}
                                    option={initConfig.option}
                                    style={initConfig.style}
                                    selector={initConfig.selector}
                                    settings={initConfig.settings}
                                />
                            </Provider>
                        </BrowserRouter>,
                        document.querySelector("#widget-app")
                    );
                },
                unmount() {
                    ReactDOM.unmountComponentAtNode(document.querySelector(initConfig.selector));
                },
            };
        },
    },
    buttonWidget: {
        init: () => {
            return {
                render: () => {
                    ReactDOM.render(
                        <PaymentButtonWidget
                            appID={initConfig.appID}
                            order={initConfig.order}
                            option={initConfig.option}
                            style={initConfig.style}
                            selector={initConfig.selector}
                        />,
                        document.querySelector("#btn-widget")
                    );
                },
                unmount() {
                    ReactDOM.unmountComponentAtNode(document.querySelector(initConfig.selector));
                },
            };
        },
    },
};
