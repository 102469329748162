import React, { useEffect, useState } from "react";
import fail_icon from "../../assets/images/checkout/fail-icon.png";
import {
    Amount,
    AmountLabel,
    Container,
    Header,
    InfoLineValue,
    InfoOrderSection,
    InfoOrderTitle,
    ItemOrder,
    LabelInfo,
    Line,
    PaymentFailContainer,
    TitleSection,
} from "./style";
import { useSelector } from "react-redux";
import { SolidButton } from "../../components/checkout/styled";
import { selectMerchantInfo } from "../../reducers/merchantSlice";
import { formatCurrency } from "../../utils/lib";
import { useTranslation } from "../../hook/use-translate";
export const PaymentFail = ({ props }) => {
    const merchantInfo = useSelector(selectMerchantInfo);
    //get query params
    const query = new URLSearchParams(window.location.search);
    const type = query.get("type");
    const settings = props?.settings;
    const { translate } = useTranslation();

    return (
        <PaymentFailContainer>
            <Header color={settings?.button_color}>{"Fresh Fruit"}</Header>
            <Container>
                <TitleSection>
                    <img src={fail_icon} alt="" width={60} />
                    <span>{translate("payment_payment_fail")}</span>
                </TitleSection>
                <div>
                    <InfoOrderTitle>{translate("order_info")}</InfoOrderTitle>
                    <InfoOrderSection>
                        <ItemOrder>
                            <LabelInfo>{translate("order_info_order_id")}:</LabelInfo>
                            <InfoLineValue>{props.order?.order_id}</InfoLineValue>
                        </ItemOrder>
                        <ItemOrder>
                            <LabelInfo>{translate("payment_total_amount")}</LabelInfo>
                            <InfoLineValue>{formatCurrency(props.order?.amount)}</InfoLineValue>
                        </ItemOrder>
                        <ItemOrder>
                            <LabelInfo>{translate("payment_payment_method")}</LabelInfo>
                            <InfoLineValue>{`${translate("payment_payment_with")} ${type}`}</InfoLineValue>
                        </ItemOrder>
                        <Line />
                        <ItemOrder>
                            <AmountLabel>{translate("payment_payment_amount")}</AmountLabel>
                            <Amount color={settings}>{formatCurrency(props.order?.amount)}</Amount>
                        </ItemOrder>
                    </InfoOrderSection>
                </div>
                <SolidButton
                    color={settings?.button_color}
                    rounded={settings?.button_border}
                    onClick={() => {
                        if (merchantInfo?.cancel_url) {
                            window.location.replace(merchantInfo.cancel_url);
                        }
                    }}
                >
                    <span>
                        {translate("payment_payment_amount")} {"Fresh Fruit"}
                    </span>
                </SolidButton>
            </Container>
        </PaymentFailContainer>
    );
};
