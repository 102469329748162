import { portalClient } from "./axiosClient";

const PORTAL_SERVICE = `/portal-service`;
const CHECKOUT_SERVICE = `/checkout-service`;
const PAYMENT_METHODS = `${CHECKOUT_SERVICE}/payment-methods`;

const portalService = {
    getPaymentMethods: (params) => {
        return portalClient.get(`https://ecom-api.atomsolution.vn/${PAYMENT_METHODS}/${params.app_id}?locale=${params.locale}`);
    },
};

export default portalService;
