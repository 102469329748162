import { useTranslation } from "../hook/use-translate";
import checkoutService from "../services/checkout.service";

function generateUniqueId() {
    return Math.random().toString(16).slice(2);
}

function formRedirect(path, params, method = "post") {
    // The rest of this code assumes you are not using a library.
    // It can be made less verbose if you use one.

    const form = document.createElement("form");
    form.method = method;
    form.action = path;

    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const hiddenField = document.createElement("input");
            hiddenField.type = "hidden";
            hiddenField.name = key;
            hiddenField.value = params[key];

            form.appendChild(hiddenField);
        }
    }

    document.body.appendChild(form);
    form.submit();
}

async function handleTxnOrder(amount, order_id, merchant_id) {
    console.log('object');
    const {language} = useTranslation()

    const data = {
        amount,
        order_id,
        txn_id: generateUniqueId(),
        merchant_id,
    };
    const response = await checkoutService.submitTxnOrder({
        locale: language === 'en'? 1: 0  ,
        data: data
    });
    const dataPayment = response.data;
    formRedirect(process.env.REACT_APP_CYBERSOURCE_ENDPOINT, dataPayment);
}

export { generateUniqueId, handleTxnOrder };
