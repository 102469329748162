import React from 'react'

const PaymentSuccess = () => {
  return (
    <>
        <div style={{width: "100vw"}}>
            <div style={{backgroundColor: "#fff", padding: 60, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                <svg viewBox="0 0 24 24" className="text-green-600 w-16 h-16 mx-auto my-6" style={{color: "green", fontWeight: 600, width: 160, height: 160, marginTop: 60}}>
                    <path fill="currentColor"
                        d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z">
                    </path>
                </svg>
                <div className="text-center" style={{textAlign: "center"}}>
                    <h3 style={{color: "gray", fontWeight: 900, textAlign: "center", fontSize: 24}}>Thanh toán thành công!</h3>
                    <p className="text-gray-600 my-2">Cảm ơn bạn đã hoàn tất thanh toán trực tuyến an toàn của mình.</p>
                    <p>Có một ngày tuyệt vời! </p>
                    <div className="py-10 text-center">
                        <a href="/" className="px-12 bg-indigo-600 hover:bg-indigo-500 text-white font-semibold py-3">
                           Quay lại
                    </a>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default PaymentSuccess