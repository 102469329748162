import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Container, Title, ButtonSubmit } from "./styled";
import { BoxPayment } from "../checkout/styled";

import LoadingPage from "../../components/loading";
import OrderInfo from "../../components/checkout/OrderInfo";
import { alertError } from "../../utils/alert";
import { generateUniqueId } from "../../utils/vpb";
import checkoutService from "../../services/checkout.service";

const TxnStatus = {
    PENDING: "pending",
    SUCCESS: "success",
    FAILED: "failed",
};

const PaymentCheckoutQR = ({ props }) => {
    const interval = useRef();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const txnType = query.get("type").toUpperCase() === "QR" ? "VA" : "MOMO";

    const [uniqueId] = useState(generateUniqueId());
    const [qrCode, setQrCode] = useState(null);
    const [momoDeeplink, setMomoDeeplink] = useState("");
    const [device, setDevice] = useState("");

    useEffect(() => {
        getDevice();
    }, []);

    useEffect(() => {
        // init data
        let data = {
            merchant_id: props.order.merchant_id.toString(),
            order_id: uniqueId,
            req_id: props.order.order_id,
            txn_type: txnType,
            amount: props.order.amount.toString(),
        };

        // fetch QR
        checkoutService
            .generateQrCode(data)
            .then((response) => {
                console.log(response.data);
                setQrCode(response.data.data.qr_base64);
                setMomoDeeplink(response.data.data.deeplink);
            })
            .catch((error) => {
                alertError("Có lỗi xảy ra, vui lòng thử lại sau");
                console.log(error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    useEffect(() => {
        const loopCheckTxn = async () => {
            checkoutService
                .getStatus(uniqueId, txnType)
                .then((response) => {
                    if (response.data === TxnStatus.SUCCESS) {
                        clearInterval(interval.current);
                        window.location.href = `/payment-callback?order-id=${query.get("order_id")}&status=true`;
                    } else if (response.data === TxnStatus.FAILED) {
                        clearInterval(interval.current);
                        window.location.href = `/payment-callback?order-id=${query.get("order_id")}&status=false`;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        };

        if (qrCode) interval.current = setInterval(loopCheckTxn, 1500);

        return () => clearInterval(interval.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [qrCode]);

    const getDevice = () => {
        const device = navigator.userAgent;
        if (device.includes("iPhone") || device.includes("iPad") || device.includes("Android")) {
            setDevice("mobile");
        } else {
            setDevice("desktop");
        }
    };

    const navigateToApp = () => {
        window.location.href = momoDeeplink;
    };

    if (!qrCode) {
        return <LoadingPage props={props} />;
    }

    return (
        <Container>
            <BoxPayment>
                {props?.option?.isTitle !== true && (
                    <Title>Chọn phương thức thanh toán QR Code {query.get("type")}</Title>
                )}
                {props?.option?.isInfo !== true && (
                    <OrderInfo
                        data={{
                            order_id: query.get("order_id"),
                            total_amount: props.order.amount,
                        }}
                    />
                )}
            </BoxPayment>
            {qrCode && <img src={"data:image/png;base64," + qrCode} alt="" />}
            {device === "mobile" && momoDeeplink && (
                <ButtonSubmit type="button" onClick={navigateToApp} style={{ height: "100%" }}>
                    Mở ứng dụng MOMO
                </ButtonSubmit>
            )}
        </Container>
    );
};
export default PaymentCheckoutQR;
