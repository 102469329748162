import React, { useEffect, useState } from 'react'
import { ButtonPayment } from '../../components/checkout/styled'
import checkoutService from '../../services/checkout.service'
import { checkoutBVBService } from '../../services/checkout-bvb.service'

function PaymentButtonWidget(props) {
  const [colorButton, setColorButton] = useState('#ed1c24')
  const handleRedirectToCheckout = () => {
    const { appID, order } = props

    // Constructing query parameters
    const queryParams = new URLSearchParams()
    queryParams.append('appId', appID)
    queryParams.append('orderId', order.order_id)
    queryParams.append('amount', order.amount)
    queryParams.append('merchantId', order.merchant_id)

    //checkout url
    const url = `${
      process.env.REACT_APP_CHECKOUT_PAYMENT_URL
    }/?${queryParams.toString()}`

    window.open(url)
  }

  useEffect(() => {
    fetchSettings()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  const fetchSettings = async () => {
    const response = await checkoutBVBService.getMerchantDetail(
      props.order.merchant_id
    )
    setColorButton(`${response?.data?.data?.widget.button_color}`)
  }

  return (
    <ButtonPayment color={colorButton} onClick={handleRedirectToCheckout}>
      THANH TOÁN
    </ButtonPayment>
  )
}

export default PaymentButtonWidget
