import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    flex-flow: column;
    max-width: 540px;
    padding: 1rem ;
    margin: 0 auto;
    font-family: 'Montserrat';
`

export const Title = styled.div`
    padding: 0rem ;
    color:#002970;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
`

export const BoxMethod = styled.div`
    padding: 0rem ;
    margin-top: 24px;
`

export const MsgError = styled.div`
    padding: 10px;
    border: 1px solid transparent;
    border-radius: 4px;  
    background-color: #fcf8e3;
    display: flex;
    img{
        width: 26px;
    }
    p{
        color: #8a6d3b;
        border-color: #faebcc;
        margin:0;
        padding:0;
        line-height:28px;
        text-indent:5px;
    }
`
export const Input = styled.input`
    width: 50%;
    padding: 0.7rem;
    margin: 20px 20px 20px 0;
    font-size: 14px;
    border-radius: 0.25rem;
    font-weight: 500;
    border: 1px solid gray;
    :focus{
        outline: none;
        /* border-color: #ED1C24; */
    }
`
export const ButtonSubmit = styled.button`
    pointer-events:${(props)=>props.disabled?'none':null};
    border-radius: 0.25rem;
    padding: 0.7rem;
    margin-top: 1.25rem;
    color: white;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    background-color: #ED1C24;
    text-align: center;
    cursor: pointer;
    border: none;
`

export const FromGroup = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    label {
        font-size: 14px;
        font-weight: 600;
    }   
`

export const Loader = styled.div `
    width: 26px;
    height: 26px;
    border: 5px solid #fff;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

`

