import React from "react";
import { ModalOverlay } from "../../pages/widget/styled";
import { ModalWrapper } from "./style";
import CloseButton from "./CloseButton";

export const ModalLayout = ({ children, handleClose }) => {
    return (
        <ModalOverlay>
            <ModalWrapper>
                {handleClose && <CloseButton handleClick={handleClose} />}
                {children}
            </ModalWrapper>
        </ModalOverlay>
    );
};
