import React from 'react'
import { CloseBtn } from './style'

const CloseButton = ({handleClick}) => {
    return (
        <CloseBtn onClick={() => handleClick()}>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                <path d="M18.5389 6L6.2876 18" stroke="url(#paint0_linear_736_36294)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M6.28775 6L18.5391 18" stroke="url(#paint1_linear_736_36294)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <defs>
                    <linearGradient id="paint0_linear_736_36294" x1="6.2876" y1="6.41753" x2="20.1354" y2="8.30389" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#001E3F"/>
                    <stop offset="1" stop-color="#181427"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear_736_36294" x1="18.5391" y1="6.41753" x2="4.69124" y2="8.30389" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#001E3F"/>
                    <stop offset="1" stop-color="#181427"/>
                    </linearGradient>
                </defs>
            </svg>
        </CloseBtn>
    )
}

export default CloseButton