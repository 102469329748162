import axios from "axios";
import { parse, stringify } from "qs";

import { LocalStorageService } from "../utils/localStorage";
const localStorageService = LocalStorageService.getService();

const defaultHeader = {
  "Access-Control-Allow-Origin": "*",
  "Content-Type": "application/json",
  Accept: "application/json",
  _v_app: localStorage.getItem("_v_app") || "1.0.0",
};

// for multiple requests
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const portalURL = process.env.REACT_APP_PORTAL_API_ENDPOINT;
const checkoutURL = process.env.REACT_APP_CHECKOUT_API_ENDPOINT;

export const portalClient = createAxiosInstance(portalURL);
export const checkoutClient = createAxiosInstance(checkoutURL);

// Set up default config for http requests here
// Please have a look at here `https://github.com/axios/axios#request- config` for the full list of configs

function createAxiosInstance(baseUrl) {
  const instance = axios.create({
    baseURL: baseUrl,
    paramsSerializer: {
      encode: parse,
      serialize: stringify,
    },
    //withCredentials: true,
    // headers: defaultHeader,
    //  paramsSerializer: (params) => queryString.stringify(params),
    // paramsSerializer:  (params) => queryString.stringify(params, {arrayFormat: 'brackets'})
  });

  // Add a request interceptor
  instance.interceptors.request.use(
    (config) => {
      const token = localStorageService.getAccessToken();
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }

      const address = localStorageService.getAccessAccount();
      if (address) {
        config.headers["address"] = address;
      }

      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  //Add a response interceptor
  instance.interceptors.response.use(
    (response) => {
      return handleResponse(response);
    },
    (error) => {
      const originalRequest = error.config;

      if (error.response.status === 401 && !originalRequest._retry) {
        console.log("HET^! TOKEN ");

        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers["Authorization"] = "Bearer " + token;
              return instance.request(originalRequest);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;
      }

      return Promise.reject(handleError(error));
    }
  );

  return instance
}

const handleResponse = (res) => {
  if (res && res.data) {
    return res.data;
  }

  return res;
};

const handleError = (error) => {
  const { data } = error.response;
  if (data && data.error_code === "USER_LOCKED_USING") {
    clearAuthToken();
  }

  return data;
};

const clearAuthToken = () => {
  LocalStorageService.clearToken();
  window.location.reload();
};
