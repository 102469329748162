import React from "react";
import GlobalStyle from "./GlobalStyle";

import { Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PaymentSuccess from "./pages/PaymentSuccess";
import { WrapperASW } from "./pages/checkout/styled";
import PaymentCheckoutCash from "./pages/payment-cash";
import PaymentCheckoutEDC from "./pages/payment-edc";
import { PaymentFail } from "./pages/payment-fail";
import PaymentCheckoutQR from "./pages/payment-qr";
import PaymentWidget from "./pages/widget";

const App = (props) => {
  return (
    <WrapperASW>
      <GlobalStyle />
      <ToastContainer />
        <Switch>
          <Route exact path="/payment-qr">
            <PaymentCheckoutQR props={props} />
          </Route>
          <Route path="/payment-success">
            <PaymentSuccess props={props} />
          </Route>
          <Route path="/payment-fail">
            <PaymentFail props={props} />
          </Route>
          <Route exact path="/payment-cash">
            <PaymentCheckoutCash props={props} />
          </Route>
          <Route exact path="/payment-edc">
            <PaymentCheckoutEDC props={props} />
          </Route>
          <Route path="/">
            <PaymentWidget props={props} />
          </Route>
        </Switch>
    </WrapperASW>
  );
};

export default App;
