import styled from "styled-components";

export const PaymentFailContainer = styled.div`
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 8px 11px -3px rgba(0, 0, 0, 0.08);
    width: 700px;
    font-family: "Montserrat";
    overflow: hidden;
    margin: 40px auto;
`;

export const Header = styled.div`
    background-color: ${(props) => props?.color || "#ED1C24"};
    padding: 24px 40px;
    text-align: center;
    color: #ffffff;
    font-size: 24px;
    font-weight: 800;
    line-height: 32px;
`;

export const Container = styled.div`
    padding: 32px;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

export const TitleSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    color: #18191b;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
`;

export const InfoOrderTitle = styled.div`
    color: "#18191B";
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    padding-bottom: 16px;
`;

export const InfoOrderSection = styled.div`
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 8px;
    background: #fafafa;
`;

export const InfoLineValue = styled.div`
    color: #262626;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
`;

export const ItemOrder = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const LabelInfo = styled.div`
    color: #8991a3;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
`;

export const AmountLabel = styled.div`
    color: #262626;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
`;

export const Amount = styled.div`
    color: #ed1c24;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
`;

export const Line = styled.div`
    width: 100%;
    height: 1px;
    background: #e8e8e8;
`;
