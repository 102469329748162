import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0rem;
  margin: 0 auto;
  font-family: 'Montserrat';
  max-width: 1320px;
`

export const Title = styled.div`
  font-family: 'Montserrat';
  color: #000;

  /* Bold/2XL 24px|32px */
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;

  //responsive
  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 28px;
    padding: 0 16px 0 16px;
  }
`

export const ContainerMethod = styled.div`
  display: flex;
  position: relative;
  max-width: 850px;
  width: 100%;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 12px;
  background: #fff;
  overflow: hidden;
  box-shadow: 0px 8px 11px -3px rgba(0, 0, 0, 0.08);
  //responsive
  @media (max-width: 768px) {
    padding: 16px;
    gap: 12px;
  }

  .img {
    position: absolute;
    right: 70px;
    bottom: -10px;
    visibility: visible;
    @media (max-width: 1340px) {
      visibility: hidden;
    }
  }
`

export const WrapperMethod = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  .payment-method {
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`

export const MsgError = styled.div`
  padding: 10px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: #fcf8e3;
  display: flex;
  img {
    width: 26px;
  }
  p {
    color: #8a6d3b;
    border-color: #faebcc;
    margin: 0;
    padding: 0;
    line-height: 28px;
    text-indent: 5px;
  }
`

export const WrapperFlexBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`

export const ModalOverlay = styled.div`
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`

export const ModalWrapper = styled.div`
  display: inline-flex;
  padding: 40px;
  align-items: flex-start;
  gap: 24px;
  border-radius: 16px;
  background: #fff;
  position: relative;

  .close {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    width: 24px;
    height: 24px;
    object-fit: cover;
  }
`

export const QRCodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  gap: 16px;
  padding: 24px 24px 16px 24px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #e4e7ec;

  .wrapper_content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
  }

  .title {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .total_amount {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }
`

export const QRCode = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  gap: 20px;
  img {
    height: 300px;
    object-fit: cover;
    @media (max-width: 768px) {
      height: 100%;
    }
  }
  @media (max-width: 768px) {
    min-height: 0px;
    min-height: 0px;
    width: 100%;
    aspect-ratio: 1;
  }
`

export const InstructionTitle = styled.div`
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
`

export const InstructionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`

export const InstructionNumber = styled.div`
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #4bb026;
  color: #ffffff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`

export const InstructionList = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`

export const InstructionLine = styled.div`
  position: absolute;
  width: 1px;
  border-left: 1px dashed #4bb026;
  height: 85%;
  left: 15.5px;
  bottom: 0;
`

export const InstructionItem = styled.div`
  max-width: 370px;
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  position: relative;
  z-index: 1;
`

export const InstructionText = styled.div`
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
`

export const PaymentWrapper = styled.div`
  background-color: #f9fafb;
  display: flex;
  padding: 16px 16px 24px 16px;
  border-radius: 8px;
  width: 100%;
  gap: 24px;

  @media (max-width: 1340px) {
    flex-direction: column;
  }
`
