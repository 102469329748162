import React, { useEffect, useRef, useState } from 'react'
import { PulseLoader } from 'react-spinners'
import img_list_logo from '../../assets/images/checkout/icon-list-logo.png'
import { useTranslation } from '../../hook/use-translate'
import checkoutService from '../../services/checkout.service'
import { alertError } from '../../utils/alert'
import { generateUniqueId } from '../../utils/vpb'
import {
  InstructionItem,
  InstructionLine,
  InstructionList,
  InstructionNumber,
  InstructionText,
  InstructionTitle,
  InstructionWrapper,
  PaymentWrapper,
  QRCode,
  QRCodeWrapper,
} from '../widget/styled'
import io from 'socket.io-client'
import { useSocket } from '../../hook/use-socket'

export const TxnStatus = {
  PENDING: 'pending',
  SUCCESS: 'success',
  FAILED: 'failed',
  TIMEOUT: 'timeout',
  CANCEL: 'cancelled',
}

function PaymentQRModal({ orderInfo, setTxnStatus, qrId }) {
  const interval = useRef()
  const txnType = 'VA'
  const [uniqueId] = useState(generateUniqueId())
  const [qrCode, setQrCode] = useState(null)
  const socket = useRef(null)
  const { translate, language } = useTranslation()

  const hasFetched = useRef(false)

  useEffect(() => {
    if (hasFetched.current) return
    hasFetched.current = true

    // init data
    let data = {
      merchant_id: orderInfo.merchant_id.toString(),
      order_id: orderInfo.order_id,
      payment_method: '2',
      amount: orderInfo.amount.toString(),
      locale: language,
      // serial_number: orderInfo.order_id,
      payment_channel: 'PAY_BY_LINK',
    }

    // fetch QR
    checkoutService
      .generateQrCode(data)
      .then((response) => {
        const qrCode = response.data?.payload?.qr_base64
        qrId.current = response.data?.id

        setQrCode(qrCode)
      })
      .catch((error) => {
        alertError('Có lỗi xảy ra, vui lòng thử lại sau')
        console.log(error)
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderInfo])

  // useEffect(() => {
  //   if (qrCode) {
  //     socket.current.on('notify-transactions', (data) => {
  //       const { status, order_id } = data
  //       if (
  //         status.toLowerCase() === TxnStatus.SUCCESS &&
  //         order_id === orderInfo.order_id
  //       ) {
  //         setTxnStatus(TxnStatus.SUCCESS)
  //       } else if (
  //         status.toLowerCase() === TxnStatus.FAILED &&
  //         order_id === orderInfo.order_id
  //       ) {
  //         setTxnStatus(TxnStatus.FAILED)
  //       }
  //     })
  //   }
  // }, [qrCode])

  useSocket({
    url: process.env.REACT_APP_SOCKET_URL,
    emitEvent: {
      name: 'subscribe',
      payload: { merchant_id: String(orderInfo.merchant_id.toString()) },
    },
    onEvent: {
      name: 'payment_success',
      func: async ({ data }) => {
        const { order_code } = data?.invoice_data
        if (order_code === orderInfo.order_id) {
          setTxnStatus(TxnStatus.SUCCESS)
        }
      },
    },
    enable: !!orderInfo.merchant_id.toString(),
  })

  useEffect(() => {
    socket.current = io(process.env.REACT_APP_SOCKET_URL)
  }, [])

  return (
    <PaymentWrapper>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          alignItems: 'center',
        }}
      >
        <p
          style={{
            fontWeight: '700',
            fontSize: '14px',
          }}
        >
          {translate('pop_up_qr_title')}
        </p>
        <QRCodeWrapper>
          <QRCode>
            {qrCode ? (
              <img src={`data:image/png;base64,${qrCode}`} alt='qr-code' />
            ) : (
              <PulseLoader
                color={'#36d7b7'}
                loading={true}
                size={10}
                speedMultiplier={0.5}
              />
            )}
          </QRCode>
          <img src={img_list_logo} alt='img_list_logo' />
        </QRCodeWrapper>
      </div>

      <InstructionWrapper>
        <InstructionTitle> {translate('pop_up_qr_guide')} </InstructionTitle>
        <InstructionList>
          <InstructionItem>
            <InstructionNumber>1</InstructionNumber>
            <InstructionText>{translate('pop_up_qr_step_1')}</InstructionText>
          </InstructionItem>
          <InstructionItem>
            <InstructionNumber>2</InstructionNumber>
            <InstructionText>{translate('pop_up_qr_step_2')}</InstructionText>
          </InstructionItem>
          <InstructionItem>
            <InstructionNumber>3</InstructionNumber>
            <InstructionText>{translate('pop_up_qr_step_3')}</InstructionText>
          </InstructionItem>

          <InstructionLine />
        </InstructionList>
      </InstructionWrapper>
    </PaymentWrapper>
  )
}

export default PaymentQRModal
