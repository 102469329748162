import React from 'react'
import { ModalLayout } from './modalLayout'
import { BorderButton, Buttons, SolidButton } from '../checkout/styled'
import fail_icon from '../../assets/images/checkout/fail-icon.png'
import {
  ContentSection,
  ModalContent,
  SubTitle,
  SubTitleHightLight,
  Title,
} from './style'
import { useTranslation } from '../../hook/use-translate'

const CancelModal = ({ onClose, onCancel, settings, merchantInfo }) => {
  const { translate } = useTranslation()

  return (
    <ModalLayout handleClose={onClose}>
      <ModalContent>
        <img src={fail_icon} alt='' width={48} />
        <ContentSection>
          <Title>{translate('pop_up_cancel')}</Title>
          <SubTitle>
            {translate('pop_up_confirm_cancel')}
            {/* <SubTitleHightLight> {'Fresh Fruit' || ''} </SubTitleHightLight>? */}
          </SubTitle>
        </ContentSection>
      </ModalContent>
      <Buttons>
        <BorderButton
          color={'#1E2A7B'}
          rounded={settings?.button_border}
          onClick={onClose}
        >
          <span>{translate('pop_up_skip')}</span>
        </BorderButton>
        <SolidButton
          color={'#1E2A7B'}
          rounded={settings?.button_border}
          onClick={onCancel}
        >
          <span>{translate('pop_up_confirm')}</span>
        </SolidButton>
      </Buttons>
    </ModalLayout>
  )
}

export default CancelModal
