import React from "react";
import { ModalLayout } from "./modalLayout";
import { BorderButton, Buttons, SolidButton } from "../checkout/styled";
import fail_icon from "../../assets/images/checkout/fail-icon.png";
import { ContentSection, ModalContent, SubTitle, Title } from "./style";
import { useTranslation } from "../../hook/use-translate";

const TimeOutModal = ({ onClose, onCancel, settings, merchantInfo }) => {
    const { translate } = useTranslation();

    return (
        <ModalLayout handleClose={onClose}>
            <ModalContent>
                <img src={fail_icon} alt="" width={48} />
                <ContentSection>
                    <Title>{translate("pop_up_time_out")}</Title>
                    {/* <SubTitle>{translate("pop_up_sure_continue")}?</SubTitle> */}
                </ContentSection>
            </ModalContent>
            <Buttons>
                <BorderButton color={settings.button_color} rounded={settings?.button_border}>
                    <span>
                        {translate("pop_up_back")}
                    </span>
                </BorderButton>
                {/* <SolidButton
                    color={settings.button_color}
                    rounded={settings?.button_border}
                    onClick={() => {
                        window.location.reload();
                    }}
                >
                    <span>{translate("pop_up_continue")}</span>
                </SolidButton> */}
            </Buttons>
        </ModalLayout>
    );
};

export default TimeOutModal;
