import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

import { selectMethodItems } from "../../reducers/methodsSlice";
import { selectMerchantInfo } from "../../reducers/merchantSlice";

import { fetchAppID, fetchPaymentMethod } from "../../actions/paymentActions";

import { Container, Title, Input, ButtonSubmit, FromGroup, Loader } from "./styled";
import { BoxPayment } from "../checkout/styled";

import LoadingPage from "../../components/loading";
import OrderInfo from "../../components/checkout/OrderInfo";
import AlertMessage from "../../components/common/AlertMessage";
import axios from "axios";
import { alertInfo } from "../../utils/alert";
import { portalClient } from "../../services/axiosClient";

const PaymentCheckoutEDC = ({ props }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const methods = useSelector(selectMethodItems);
    const merchant = useSelector(selectMerchantInfo);

    const [msg, setMsg] = useState("");
    const [host, setHost] = useState("192.168.1.84")
    const [port, setPort] = useState(8080)
    const [verifyCode, setVerifyCode] = useState("386922")
    const [isLoading, setIsLoading] = useState(false)

    const interval = useRef();
    const history = useHistory()

    useEffect(() => {
        // fetch metadata
        if (props && props.appID) {
            fetchMerchant(props.appID);
        }

    }, [props]);

    useEffect(() => {
        const loopCheckTxn = async () => {
            const status = await fetchTxnStatus();
            if (status === true) {
                alertInfo('Giao dịch thành công')
                clearInterval(interval.current);
                history.push("/payment-success")
            }
        };

        interval.current = setInterval(loopCheckTxn, 5000);

        return () => clearInterval(interval.current);

    }, [])


    const fetchTxnStatus = async () => {
        const response = await portalClient({
            method: "GET",
            url: "/v1/checkout/txn",
            params: { "order_id": query.get("order_id") }
        })
        return response.data
    }

    const onSubmit = (event) => {
        event.preventDefault()
        const _request_txn = {
            "direct": 1,
            "requestID": query.get("order_id"),
            "orderId": query.get("order_id"),
            "requestType": "SALE",
            "ipnUrl": "https://ecom-stag.atomsolution.vn/v1/checkout/callback",
            "amount": props.order?.amount,
            "tip": 0,
            "extraData": []
        }
        axios({
            url: "http://" + host + ':' + port,
            method: "POST",
            data: _request_txn,
            params: {
                verify_code: verifyCode
            },
        }).then(response => {
            console.log(response.data);
        }).catch(err => {
            console.log(err);
        })

        setIsLoading(true)
    }

    const fetchMerchant = async (app_id) => {
        const data = await dispatch(fetchAppID({ app_id }));
        if (data.payload.msg) {
            setMsg(data.payload.msg);
        }
        if (data) {
            await dispatch(fetchPaymentMethod({}));
        }
    };

    if (msg) {
        return <AlertMessage msg={msg} />;
    }

    if (!merchant || !methods) {
        return <LoadingPage props={props} />;
    }

    return (
        <Container>
            <BoxPayment>
                <Title>Thanh toán POS EDC</Title>
                <OrderInfo
                    data={{
                        order_id: query.get("order_id"),
                        total_amount: props.order?.amount,
                        ...merchant,
                    }}
                />
            </BoxPayment>
            <form id="payCash" onSubmit={onSubmit} style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex" }}>
                    <img style={{ flex: 2 }} width="150" src="https://sc04.alicdn.com/kf/H31a3db0a934a4f4aaa2c57ef2c43c8258.png" alt="" />
                    <div style={{ display: "flex", flexDirection: "column", flex: 3 }}>
                        <FromGroup>
                            <label htmlFor="">Host: </label>
                            <Input type="text" required placeholder="Host" value={host} onChange={e => setHost(e.target.value)} />
                        </FromGroup>
                        <FromGroup>
                            <label htmlFor="">Port: </label>
                            <Input type="number" required placeholder="Port" value={port} onChange={e => setPort(e.target.value)} />
                        </FromGroup>
                        <FromGroup>
                            <label htmlFor="">Verify code: </label>
                            <Input type="text" required placeholder="Verify code" value={verifyCode} onChange={e => setVerifyCode(e.target.value)} />
                        </FromGroup>
                    </div>
                </div>
                <ButtonSubmit type="submit" id="btnPayment" disabled={isLoading} >
                    {!isLoading ? "Thanh toán" :
                        <Loader id="loader" ></Loader>
                    }
                </ButtonSubmit>

            </form>

        </Container>
    );
};
export default PaymentCheckoutEDC;
