import styled from "styled-components";

export const ModalWrapper = styled.div`
    min-width: 400px;
    border-radius: 16px;
    background: #FFF;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    position: relative;
`

export const CloseBtn = styled.div`
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
`

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
`
export const Title = styled.div`
    color: #0A203D;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    padding: 2px 0px;
`
export const SubTitle = styled.div`
    color: #0A203D;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
`
export const SubTitleHightLight = styled.span`
    font-weight: 600;
`

export const ContentSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;

`