/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { selectMethodItems } from '../../reducers/methodsSlice'
import { fetchPaymentMethod } from '../../actions/paymentActions'
import { handleTxnOrder } from '../../utils/vpb'

import OrderInfo from '../../components/checkout/OrderInfo'
import PaymentMethod from '../../components/checkout/PaymentMethod'
import LoadingPage from '../../components/loading'
import { Container, ContainerMethod, Title, WrapperMethod } from './styled'
import { BoxPayment } from '../checkout/styled'
import PaymentQRModal, { TxnStatus } from '../payment-qr-modal'
import SuccessModal from '../../components/modal/SuccessModal'
import CancelModal from '../../components/modal/CancelModal'
import checkoutService from '../../services/checkout.service'
import TimeOutModal from '../../components/modal/TimeOutModal'
import {
  selectMerchantInfo,
  setMerchantInfo,
} from '../../reducers/merchantSlice'
import { useTranslation } from '../../hook/use-translate'
import icQr from '../../assets/images/checkout/qr-icon.png'
import icQr2 from '../../assets/images/checkout/qr_2.png'
import {
  ModalOverlay,
  ModalWrapper,
  QRCodeWrapper,
  QRCode,
  InstructionWrapper,
  InstructionTitle,
  InstructionItem,
  InstructionNumber,
  InstructionText,
} from '../widget/styled'
import { PulseLoader } from 'react-spinners'
import { formatCurrency } from '../../utils/lib'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import PaymentSuccess from '../../components/widget/payment-success'
import PaymentError from '../../components/widget/payment-error'

const KEY_ID_CHECKOUT_SACOMBANK = '63896905fdc876c9c9dcfe41'
const KEY_ID_CHECKOUT_QR = '63896944fdc876c9c9dcfe42'
const KEY_ID_CHECKOUT_QR_MOMO = '65590140e459fdef0628cbe9'
const KEY_ID_CHECKOUT_CASH = '6559d5084d337fbe7008afd2'
const KEY_ID_CHECKOUT_EDC = '6559f1464d337fbe7008afd5'
const KEY_ID_CHECKOUT_VP = '65954330226b552c954b6f4c'

const PaymentWidget = ({ props }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const methods = useSelector(selectMethodItems)
  const [activeMethod, setActiveMethod] = useState(0)
  const [qrType, setQrType] = useState('')
  const settings = props?.settings
  const [txnStatus, setTxnStatus] = useState('')
  const merchantInfo = useSelector(selectMerchantInfo)
  const { language, translate } = useTranslation()
  const qrId = useRef(null)
  const [navigateError, setNavigateError] = useState(false)
  const { order_id: orderId, amount, created_at, merchant_id } = props?.order
  const createdAtParam = created_at ? `&created_at=${created_at}` : ''

  const closeQrModal = () => {
    setQrType('')
  }

  const closeTxnModal = () => {
    setTxnStatus('')
  }
  const checkoutOrder = async () => {
    switch (activeMethod) {
      // case KEY_ID_CHECKOUT_SACOMBANK:
      //   createSignPayment();
      //   break;
      case 1:
        // history.push("/payment-qr?type=QR&order_id=" + orderId);
        setQrType('CASH')
        break

      case 2:
        // history.push("/payment-qr?type=QR&order_id=" + orderId);
        setQrType('QR')
        break

      case 3:
        // history.push("/payment-qr?type=MOMO&order_id=" + orderId);
        setQrType('MOMO')
        break

      case 10:
        history.push('/payment-cash?order_id=' + orderId)
        break

      // case KEY_ID_CHECKOUT_EDC:
      //   await submitTxn("EDC");
      //   history.push("/payment-edc?order_id=" + orderId);
      //   break;

      case 4:
        handleTxnOrder(amount, orderId, merchant_id)
        break

      default:
        alert('Coming soon...')
        break
    }
  }

  const confirmCancelOrder = async () => {
    try {
      await checkoutService.cancelPayment({
        id: qrId.current,
        status: TxnStatus.CANCEL.toUpperCase(),
      })
      toast.success(translate('cancel_payment_success'))
      if (txnStatus === TxnStatus.TIMEOUT)
        window.location.href = `${process.env.REACT_APP_CHECKOUT_PAYMENT_URL}/payment-expired`
      if (txnStatus === TxnStatus.CANCEL)
        window.location.href = `${process.env.REACT_APP_CHECKOUT_PAYMENT_URL}/payment-cancelled`
      setNavigateError(true)
    } catch (error) {
      toast.error(translate('cancel_payment_failed'))
    }
  }

  const cancelOrder = async () => {
    setTxnStatus(TxnStatus.CANCEL)
    console.log('cancelOrder')
  }

  // const fetchMerchantInfo = async () => {
  //     try {
  //         const res = await checkoutService.getMerchantInfo(props.order.merchant_id);
  //         dispatch(setMerchantInfo(res.data));
  //     } catch (error) {
  //         console.log(error);
  //     }
  // };

  useEffect(() => {
    if (txnStatus.toLowerCase() === TxnStatus.TIMEOUT) {
      confirmCancelOrder()
    }
  }, [txnStatus])

  if (!methods) {
    return <LoadingPage props={props} />
  }

  if (txnStatus === TxnStatus.SUCCESS || (!!qrType && qrType === 'CASH'))
    window.location.href = `${process.env.REACT_APP_CHECKOUT_PAYMENT_URL}/payment-success?order_id=${orderId}&merchant_id=${merchant_id}&amount=${amount}${createdAtParam}`

  return (
    <>
      {settings && (
        <>
          <Container>
            <div style={{ width: '100%' }}>
              {props?.option?.isTitle !== true && (
                <Title>{translate('order_info_payment')}</Title>
              )}
            </div>
            <BoxPayment>
              <ContainerMethod>
                <WrapperMethod>
                  <div
                    style={{
                      display: 'flex',
                      gap: '12px',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img src={icQr} alt='icon_qr' width={32} height={32} />

                    <div
                      style={{
                        height: '20px',
                        width: '1px',
                        backgroundColor: '#E3E3E3',
                      }}
                    />

                    <div
                      style={{
                        fontSize: '16px',
                        fontWeight: 'bold',
                      }}
                    >
                      {translate('pop_up_qr_payment')}
                    </div>
                  </div>

                  <PaymentQRModal
                    // type={qrType}
                    orderInfo={props.order}
                    setTxnStatus={setTxnStatus}
                    qrId={qrId}
                  />
                </WrapperMethod>
                <img src={icQr2} alt='ic_qr_2' className='img' />
              </ContainerMethod>

              <OrderInfo
                style={settings}
                onCheckout={checkoutOrder}
                onCancel={cancelOrder}
                data={props.order}
                activeMethod={activeMethod}
                merchantInfo={merchantInfo}
                setTxnStatus={setTxnStatus}
              />
            </BoxPayment>
          </Container>
          {qrType && qrType !== 'CASH' && (
            <PaymentQRModal
              type={qrType}
              orderInfo={props.order}
              onClose={closeQrModal}
              setTxnStatus={setTxnStatus}
              qrId={qrId}
            />
          )}
          {txnStatus === TxnStatus.CANCEL && (
            <CancelModal
              settings={settings}
              onClose={closeTxnModal}
              onCancel={confirmCancelOrder}
              merchantInfo={merchantInfo}
            />
          )}
        </>
      )}
    </>
  )
}
export default PaymentWidget
