import { checkoutClient, portalClient } from "./axiosClient"

const CHECKOUT_SERVICE = `/checkout-service`
const STATUS = `${CHECKOUT_SERVICE}/txn`
const SETTINGS_WIDGET = `${CHECKOUT_SERVICE}/setting`
const GET_MERCHANT_INFO = `${CHECKOUT_SERVICE}/merchant`
const CHECKOUT_PAYMENT = '/payments'

const checkoutService = {
  submitTxnOrder: (params) => {
    return portalClient.post(
      `${CHECKOUT_SERVICE}/txn-order?locale=${params.locale}`,
      params.data
    )
  },
  generateQrCode: (params) => {
    return checkoutClient.post(CHECKOUT_PAYMENT, params)
  },
  getStatus: (txn_id, txn_type) => {
    return portalClient.get(`${STATUS}?txn_id=${txn_id}&txn_type=${txn_type}`)
  },
  getSettingsWidget: (app_id) => {
    return portalClient.get(`${SETTINGS_WIDGET}/${app_id}`)
  },
  getMerchantInfo: (merchant_id) => {
    return portalClient.get(`${GET_MERCHANT_INFO}/${merchant_id}`)
  },
  cancelPayment: (params) => {
    return checkoutClient.put(CHECKOUT_PAYMENT, params)
  },
}

export default checkoutService
