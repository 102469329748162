// useTranslation.js
import enTranslations from '../assets/locales/en.json';
import viTranslations from '../assets/locales/vi.json';

const translations = {
  vi: viTranslations,
  en: enTranslations,
};

export const useTranslation = () => {
  const language = localStorage.getItem('language') || 'vi';
  const setLanguage = (language) => {
    localStorage.setItem('language', language);
    window.location.reload();
  };

  const translate = (key) => translations[language][key] || key;

  return { translate, language, setLanguage };
};
